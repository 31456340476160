<template>
  <div
      class="app-content content"
      :class="[{'show-overlay': $store.state.app.shallShowOverlay}]"
  >
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <b-card no-body>
            <div class="m-2">
              <div class="row">
                <div class="col">
                  <v-select
                      v-model="productSpecification"
                      :options="products"
                      :filterable="false"
                      label="product_id"
                      class="item-selector-title w-100"
                      placeholder="Выберите товар"
                      @search="searchProduct"
                  >
                    <template #selected-option="{ size_name, product }">
                      {{ product.sku }} - Размер {{ size_name }}
                    </template>
                    <template #option="{ size_name, qty, condition_name, product }">
                      {{ product.sku }} - Размер {{ size_name }}<br>
                      <small>{{ product.name }} {{ product.brand_name }}</small><br>
                      <small>{{ condition_name }}, В наличии: {{ qty }} шт</small>
                    </template>
                  </v-select>
                </div>
                <div class="col">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="getHistory"
                  >
                    Искать
                  </b-button>
                </div>
              </div>
            </div>
            <div class="row" v-if="history">
              <div class="col-12">
                <h4 class="p-2">Движения товара</h4>
                <div class="position-relative table-responsive">
                  <table class="table b-table">
                    <thead>
                    <!---->
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th>Дата</th>
                      <th>Тип движения</th>
                      <th>Статус</th>
                      <th v-if="$can('read', 'users')">Клиент/Поставщик</th>
                      <th>Количество</th>
                      <th v-if="$can('read', 'payments')">Цена</th>
                      <th v-if="$can('read', 'payments')">Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in history" :key="index" :class="rowClass(row.type)">
                      <td>{{ index + 1 }}</td>
                      <td>{{ row.id }}</td>
                      <td>{{ row.created_at | formattedDate('dd.MM.yyyy HH:mm:ss') }}</td>
                      <td>{{ getType(row.type) }}</td>
                      <td>{{ row.status_name }}</td>
                      <td v-if="$can('read', 'users')" style="white-space: nowrap">
                        {{ row.user_name }} {{ row.user_surname }} <br>
                        <small>({{ row.user_company_name }})</small>
                      </td>
                      <td>{{ row.qty }}</td>
                      <th v-if="$can('manage', 'payments')">
                        <span class="amount">{{ row.cost }}</span> <span class="currency">{{ row.currency_name }}</span>
                      </th>
                      <th v-if="$can('manage', 'payments')">
                        <span class="amount">{{ row.cost * row.qty }}</span> <span class="currency">{{ row.currency_name }}</span>
                      </th>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-card>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'History',
  components: {
    BCard, BButton, vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      history: [],
      productSpecification: null,
      products: [],
    };
  },
  methods: {
    async getHistory() {
      if (this.productSpecification) {
        this.history = (await this.$api.productSpecifications.history(this.productSpecification.id)).data;
      }
    },
    async searchProduct(query, loading) {
      if (query.length) {
        loading(true);
        this.$api.products.searchAll({ search: query }).then((response) => {
          this.products = response.data;
          loading(false);
        });
      }
    },
    rowClass(type) {
      switch (type) {
        case 'arrival':
        case 'return':
          return 'row-green';
        case 'order':
          return 'row-danger';
        default:
          return '';
      }
    },
    getType(type) {
      switch (type) {
        case 'arrival':
          return 'Приход';
        case 'return':
          return 'Возврат';
        case 'order':
          return 'Заказ';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.row-green {
  background-color: #e6ffe6;
}

.row-danger {
  background-color: #ffe6e6;
}

.amount {
  font-size: 18px;

}

.currency {
  font-size: 10px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
